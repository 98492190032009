<template>
    <div>
      <v-card >
        <v-toolbar
            color="#4ECDC4"
            dark
            flat
        ><span class="text-h6">{{ this.name }}</span>
        </v-toolbar>
      
        <v-card-text>
              <div style="width:100%; max-width:500px; margin:0 auto;"><canvas id="canvas"  ></canvas></div>
          
        </v-card-text>
         
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn class="ma-2"
              outlined
              color="#78909C"   
              @click="close">閉じる
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>

      </div>
      

</template> 

<script>
// import axios from "axios"
import  QRCode  from 'qrcode';

export default {
  name: 'DialogQRCode',
  props:["name","id"],
  data () {
    return {
     QRCode:null,
    }
  },

  watch:{
    id: function(){
      this.makeQRCode();
    }
  },

  mounted(){
      this.makeQRCode();
  },


  methods: {

    makeQRCode(){
      let qr = document.getElementById("canvas")
      let dic = {naviId:this.id};
      QRCode.toCanvas(qr, JSON.stringify(dic), 
      function (error) {
        if (error) console.error(error)
        console.log('success!');
      })
      qr.style="width:100%; height:100%;  max-width:500px; max-height:500px;"
    
    },

    close(){
      this.$emit("closeQRCodeEmit")
    }
 
  },
}

</script>
